import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../components";

import locales from "../../constants";

const SpokojWGlowie = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Spokoj w głowie",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/spokoj_og.jpeg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #F8B030, #D89A2E, #DDA33B, #F5AB29, #FFC04D )",
        icons: "#f8b030",
        navClass: "spokoj__nav",
        ogImage: require("../../assets/img/portfolio/spokoj_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/spokoj-w-glowie/",
      }}
    >
      <PortfolioHeader name="spokoj" height="260" />
      <div className="spokoj">
        <section
          className="container-fluid portfolio-intro spokoj-section-2"
          id="info"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Spokój w głowie</h1>
                <ul>
                  <li>Strona internetowa</li>
                  <li>Logo</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner inner--divider">
                <p>
                  Przeprowadziliśmy rebranding poradni psychologicznej Spokój w
                  głowie projektując logo oraz stronę internetową.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="spokoj-section-3">
          <div className="inner">
            <h2>Logo</h2>
            <p>
              Projekt logo zakładał stworzenie zupełnie nowego znaku
              graficznego. Znak, zgodnie z briefem projektowym, który dostarczył
              klient, miał nienawiązywać bezpośrednio do głowy człowieka, a być
              skojarzeniem emocjonalnym z nazwą przychodni. Na bazie wytycznych
              powstał nowy znak, który składa się z sygnetu przedstawiającego
              wschód słońca nad taflą spokojnej wody, oraz prostej bezszeryfowej
              typografii.
            </p>
          </div>
          <div className="logo-presentation">
            <div className="inner">
              <div className="logo_wrapper">
                <img
                  src={require("../../assets/img/portfolio/spokoj_logo_old.png")}
                  alt=""
                />
                <span>Stare logo</span>
              </div>
              <div className="logo_wrapper">
                <img
                  src={require("../../assets/img/portfolio/spokoj_logo_new.svg")}
                  alt=""
                />
                <span>Nowe logo</span>
              </div>
            </div>
          </div>
        </section>
        <section className="spokoj-section-4">
          <div className="laptop_wrapper">
            <div className="img_wrapper">
              <img
                src={require("../../assets/img/portfolio/spokoj_full_page.png")}
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="spokoj-section-5">
          <div className="inner">
            <h2>Strona internetowa</h2>
            <p>
              Stworzyliśmy nową stronę internetową poradni psychologicznej
              Spokój w głowie. Głównym celem projektu było zaprojektowanie i
              zakodowanie nowoczesnego i szybkiego serwisu z systemem
              rezerwacyjnym dla pacjentów poradni. Kluczowym aspektem na którym
              najbardziej zależało klientowi było utrzymanie pozycji SEO,
              ponieważ stary serwis miał wypracowaną przez wiele lat pozycję w
              wynikach wyszukiwania. Udało nam się osiągnąć wszystkie zakładane
              dla strony cele bez utraty jakichkolwiek pozycji względem SEO
              przez Klienta.
            </p>
          </div>
          <div className="image-group">
            <div>
              <img
                src={require("../../assets/img/portfolio/spokoj_main_2.png")}
                alt=""
              />
            </div>
            <div>
              <img
                src={require("../../assets/img/portfolio/spokoj_main_1.png")}
                alt=""
              />
            </div>
          </div>
          <div className="image-group-last">
            <div>
              <img
                src={require("../../assets/img/portfolio/spokoj_main_4.png")}
                alt=""
              />
            </div>
            <div>
              <img
                src={require("../../assets/img/portfolio/spokoj_main_3.png")}
                alt=""
              />
            </div>
          </div>
          <img
            className="iphone"
            src={require("../../assets/img/portfolio/spokoj_iphone.png")}
            alt=""
          />
        </section>
      </div>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default SpokojWGlowie;
